<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <el-button type="text" @click="showSearch = !showSearch"><font-awesome-icon icon="search" /></el-button>
      </div>
      <div class="card-body" v-if="showSearch">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Coin Date">
                <el-date-picker v-model="search.date" type="daterange" size="small" :picker-options="datePickerOptions"
                  class="w-full" range-separator="To" start-placeholder="Start date" end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input clearable size="small" type="text" v-model="search.search_keyword"
                  placeholder="Input search keyword" class="mr-2" />
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Coin Status">
                <el-select size="small" v-model="search.status_filter" class="mr-2">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value"
                    :label="item.text" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- <div class="flex">
          <div class="w-1/2">
            &nbsp;
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Payment Vendor">
                <el-select size="small" v-model="search.payment_vendor_filter" class="mr-2">
                  <el-option v-for="(item, index) in payment_vendor_filter_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div> -->
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search" />
          Search</el-button>
        <!-- <el-button type="primary" @click="handlerDownloadBtn" size="small"><font-awesome-icon icon="download" />
          Download</el-button> -->
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="25%">Title</th>
              <th width="25%">Description</th>
              <th width="20%">Type</th>
              <th width="20%">Amount</th>
              <th class="child1-sticky">Timestamp</th>
              <!-- <th class="child1-sticky">Action</th> -->
            </tr>
          </thead>
          <!-- <tbody v-loading="isLoading"> -->
          <tbody>
            <tr v-for="(item, i) in list" :key="i">
            <!-- <tr v-for="(item, i) in list_hard_code" :key="i">    -->
              <td>{{ item.title }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ formatDate(item.timestamp) }}</td>
              <!-- <td class="text-nowrap text-muted child1-sticky">
                <button @click="detailCoinHistory(item)" title="View Detail" type="button"
                class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
     </div>
    <div>
    </div>
    <b-modal size="xl" v-model="showHistoryCoin" :title="'Detail coin #' + selected_transaction.id"
      hide-footer>
      <el-descriptions v-if="!loading_detail" class="margin-top mb-3" title="Order Information" :column="2" border>   
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Name
          </template>
          {{ selected_transaction.full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            Email
          </template>
          {{ selected_transaction.email }}
        </el-descriptions-item>
         <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Company Name
          </template>
          {{ selected_transaction.company_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            Phone Number
          </template>
          {{ selected_transaction.phone_number }}
        </el-descriptions-item>    
      </el-descriptions>
      <div>
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">History Coin</div>
        </div>
        <!-- <div class="card-body">
          <el-timeline>
            <el-timeline-item v-for="item in history_coin" :timestamp="formatDate(item.timestamp)"
              placement="top"
              color="#ff8f15"
              type="#ff8f15"
              :key="item.title">
              <el-card>
              <p>{{ item.title }} {{ item.amount }} at {{ formatDate(item.timestamp) }}</p>
                </el-card>
            </el-timeline-item>
          </el-timeline>
        </div> -->
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="25%">Date</th>
                <th width="25%">Title</th>
                <th width="20%">Amount</th>
                <th width="20%">Type</th>
                <th width="20%">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in history_coin" :key="i">   
                <td>{{ formatDate(item.timestamp) }}</td>
                <td>{{ item.title }}</td>
                <td>{{ formatNumber(item.amount) }}</td>
                <td>
                  <template v-if="item.type === 'credit'">
                    <span class="tag tag-info">Credit</span>
                  </template>
                  <template v-else-if="item.type === 'debit'">
                    <span class="tag tag-success">Debit</span>
                  </template>
                  <template v-else>
                    <span class="tag tag-default">-</span>
                  </template>
                </td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { GET_COIN_HISTORY, GET_COIN_ALL } from '@/store/modules/coin';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';

export default {
  components: { Pagination },
  name: 'Coin Balance and History',
  metaInfo: {
    title: 'Coin Balance',
  },
  data() {
    return {
      // owned_by: 24963,
      // owned_by: 25176,
      history_coin: [],
      isLoadingHistory: false,
      emptyStateImage,
      showHistoryCoin: false,
      showSearch: true,
      search_options: [
        { value: 'full_name', text: 'Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      user_email: null,
      search_keyword: '',
      search_by: '',
      selected_transaction: {
        user: {},
      },
      selected_item: {},
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'full_name',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      status_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Waiting Payment',
          value: 1,
        },
        {
          label: 'Process',
          value: 2,
        },
        {
          label: 'Success',
          value: 3,
        },
        {
          label: 'Expired',
          value: 4,
        },
        {
          label: 'Failed',
          value: 5,
        },
      ],
      payment_vendor_filter_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Qris',
          value: 'qris',
        },
        {
          label: 'Espay',
          value: 'espay',
        },
        {
          label: 'M-bayar',
          value: 'e2pay',
        },
        {
          label: 'Xendit',
          value: 'xendit',
        },
      ],
      product_status: [],
      loadingProductStatus: false,
      hard_code_option: [
        {
          id: 11,
          action_str: 'get coin',
          dot_color: '#ff8f15',
          created_at_simple: "12 Januari 1000",
          name: "Cahya",
          created_at_str: "11 Januari 1999, 20:19 AM"
        },
      ],
      api: [
        {
            title: "Transfer to Tobok",
            description: "Transfer to Tobok",
            type: "credit",
            amount: 20000,
            timestamp: "2024-05-20T02:11:34.635269Z"
        },
        {
            title: "Transfer to Tobok",
            description: "Transfer to Tobok",
            type: "credit",
            amount: 50000,
            timestamp: "2024-05-19T20:58:48.397235Z"
        },
      ],
      list_hard_code: [
        {
          id: 111,
          invoice_number: 'INV-11111-8889-swde',
          full_name: 'Cahyadi',
          email: 'cahya@gmail.com',
          phone_number: "09812435",
          company_name: 'VFI',
        },
        {
          id: 222,
          invoice_number: "INV-222-3333-rfgtg",
          full_name: "Rina",
          email: 'rina@gmail.com',
          phone_number: 90909,
          company_name: "Venteny",
        },
        {
          id: 333,
          invoice_number: "INV-311-2121-frfg",
          full_name: "Fadir",
          email: 'fadir@gmail.com',
          phone_number: 1334,
          company_name: "Matahari",
        }
      ]
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getListCoin();
    loader.hide();
  },
  methods: {
    capitalizeFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    viewHistoryCoin(item) {
      this.selected_transaction = item;
      this.selected_item = item;
      this.showHistoryCoin = true;
    },
    async detailCoinHistory(item) {
        this.selected_transaction = item;
        this.showHistoryCoin = true;
        try {
            this.loading = true;
            const response = await this.$store.dispatch(GET_COIN_HISTORY, { owned_by: this.owned_by, page: this.page });
            const historyData = response.data.rows.map(entry => ({
                title: entry.title,
                description: entry.description,
                type: entry.type,
                amount: entry.amount,
                timestamp: entry.timestamp
            }));
            
            this.history_coin = historyData; 
        } catch (error) {
            this.$message.error('Error fetching coin history');
        } finally {
            this.loading = false;
        }
    },
    async getListCoin() {
      this.isLoading = true;
      const opt = {
        page: this.page,
        owned_by: this.owned_by,
      };
      await this.$store.dispatch(GET_COIN_ALL, opt).catch(() => {});
      const { rows, count } = this.$store.getters.coins;
      this.total_rows = count || 0;
      this.list = rows;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('D MMMM YYYY, HH:mm A');
    },
    formatNumber(value) {
      return new Intl.NumberFormat('id-ID').format(value);
    }
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
</style>
